<template>
    <div class="flex-box vertical content md" v-if="info">
        <div class="detail-ctx">
            <div class="other-title">基本信息</div>
            <div class="detail-info-item">
                <div class="detail-info-label">日期</div>
                <div class="detail-info-value">{{info.create_time}}</div>
            </div>
            <div class="detail-info-item">
                <div class="detail-info-label">用户昵称</div>
                <div class="detail-info-value">{{info.username}}</div>
            </div>
            <div class="detail-info-item">
                <div class="detail-info-label">内容</div>
                <div class="detail-info-value">{{info.detail.content || '无'}}</div>
            </div>
            <div class="detail-info-item">
                <div class="detail-info-label">图片</div>
                <div class="detail-info-value">
                    <div class="file-list" v-if="info.detail.imgList && info.detail.imgList.length > 0">
                        <img class="file-item" :src="f" v-for="(f, i) in info.detail.imgList" :key="i">
                    </div>
                    <div v-else>无</div>
                </div>
            </div>
            <template v-if="info.detail.reply">
                <div class="other-title">回复</div>
                <div class="detail-info-item">
                    <div class="detail-info-label">日期</div>
                    <div class="detail-info-value">{{info.update_time}}</div>
                </div>
                <div class="detail-info-item">
                    <div class="detail-info-label">回复人</div>
                    <div class="detail-info-value">
                        <open-data type="userName" :openid="info.detail.reply_user_id"/>
                    </div>
                </div>
                <div class="detail-info-item">
                    <div class="detail-info-label">内容</div>
                    <div class="detail-info-value">{{info.detail.reply || '无'}}</div>
                </div>
            </template>
        </div>
        <a-space class="detail-footer">
            <a-button type="primary" @click="showReply" v-if="info.btn">回复</a-button>
        </a-space>
        <a-modal
            title="回复"
            :maskClosable="false"
            :width="480"
            @ok="confirmReply"
            v-model="visible">
            <a-textarea v-model="content" placeholder="请输入" :rows="4" />
        </a-modal>
    </div>
</template>

<script>
    import {getUserWxId} from "../../common/js/storage";

    export default {
        name: "RecManuscript",
        data() {
            return {
                info: null,
                visible: false,
                content: null
            }
        },
        created() {
            this.getDetail();
        },
        methods: {
            confirmReply() {
                let content = this.content;
                if(content) {
                    let info = this.info;
                    let detail = info.detail;
                    detail.reply = content;
                    detail.reply_user_id = getUserWxId();
                    this.$axios({
                        url: `/admin/mini-activity/${info.id}`,
                        method: 'PATCH',
                        data: {
                            detail: JSON.stringify(detail)
                        }
                    }).then(() => {
                        this.$message.success("已回复");
                        this.getDetail();
                        this.visible = false;
                    })
                } else {
                    this.$message.warning("请输入回复内容");
                }
            },
            getDetail() {
                let id = this.$route.params.id;
                let url = `/admin/mini-activity/${id}`;
                this.$axios(url).then(res => {
                    res.username = res.miniUser && res.miniUser.uname ? res.miniUser.uname : '微信用户';
                    let detail = res.detail;
                    if(detail) {
                        try {
                            res.detail = JSON.parse(detail);
                            if(!res.detail.reply) {
                                res.btn = true;
                            }
                        } catch (e) {
                            console.error(e);
                            res.detail = {};
                            res.btn = true;
                        }
                    }
                    this.info = res;
                })
            },
            showReply() {
                let info = this.info;
                if(info.detail.rely) return;
                this.visible = true;
            }
        }
    }
</script>

<style scoped lang="less">
    .detail-ctx {
        flex: 1;
        overflow: auto;
        padding: 10px 30px;
    }
</style>
